nav {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 4rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    display: flex;
    backdrop-filter: blur(10px);
}

ul {
    list-style: none;
}

li {
    background: transparent;
    padding: 0 2.5rem 0 2.5rem;
    border-radius: 50%;
    display: inline-block;
    color: var(--color-light);
}

nav a {
    font-size: 1.1rem; 
}

nav a:hover {
    background: rgba(0, 0, 0, 0.6);
}

.mobileMenu{
    display: none;
}

.menu{
    display: none;
}

@media screen and (max-width: 768px) {
    
    ul {
        display: none;
    }

    .mobileMenu{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 25px;
        height: 20px;
        cursor: pointer;
        text-align: start;
    }

    .line{
        width: 100%;
        height: 3px;
        background-color: #4db5ff;
    }

    .menu{
        position: fixed;
        height: 100vh;
        width: 50vw;
        background-color: black;
        top: 0;
        margin: 0;
        padding: 0;
        font-weight: bold;
        color: lightgray;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        z-index: 999;
        transition: all 1s ease;
        text-decoration: underline;
    }


}


