header {
    margin: 0px auto;
    max-width: 1600px;
}

.header__container {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    padding: 0px;
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

p {
    margin: 20px 0px 0px;
    max-width: 540px;
    color: #7D87A5;
}

h1 {
    font-weight: 600;
    color: white;
    font-size: clamp(40px, 3vw, 80px);
}

h3 {
    display: block;
    margin-top: 10px;
    line-height: 1.2;
    font-size: clamp(40px, 3.5vw, 80px);
}

h2, h3 {
    color: #CCD6F6;
}


.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: fixed;
    left: 4rem;
    bottom: 0;
}

.header_socials::after {
    content: '';
    width: 1px;
    height: 4rem;
    background: var(--color-primary);
}

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    padding: 5rem 1.5rem 1.5rem 1.5rem ;
}






@media screen and (max-width: 1024px) {
    
    .header {
        height: 68vh;
        padding: 0;
    }

    .header_socials {
        display: none;
    }

    .cta {
        justify-content: center;
    }

    .header__container {
         padding: 0;
    }
}



@media screen and (max-width: 600px) {
    
    .header {
        height: 100vh;
    }

    .header_socials {
        display: none;
    }

    .cta {
        justify-content: center;
    }

    .header__container {
        padding: 0;
   }
   
}